import { render, staticRenderFns } from "./Test.vue?vue&type=template&id=3535f6e2&scoped=true"
import script from "./Test.vue?vue&type=script&lang=js"
export * from "./Test.vue?vue&type=script&lang=js"
import style0 from "./Test.vue?vue&type=style&index=0&id=3535f6e2&prod&scoped=true&lang=css"
import style1 from "@/views/act/assets/css/act.css?vue&type=style&index=1&id=3535f6e2&prod&scoped=true&lang=css&external"
import style2 from "@/views/act/assets/css/simplebar.css?vue&type=style&index=2&id=3535f6e2&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3535f6e2",
  null
  
)

export default component.exports