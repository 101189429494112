<template>
  <div class="act-box">
    <div class="wrapper">
      <header class="top-header">
        <nav class="navbar navbar-expand">
          <div class="left-topbar d-flex align-items-center">
            <div class="act-logo"></div>
          </div>
          <div class="mid-bar">
            <button
              type="button"
              class="btn md-button"
              onclick="location='/exam/question.php?type=english&amp;examid=202012&amp;mode=0&amp;no=1'"
            >
              Prev
            </button>
            <button
              type="button"
              class="btn nav-button md-button"
              data-toggle="modal"
              data-target="#navsection"
            >
              Nav
            </button>
            <button
              type="button"
              class="btn md-button"
              onclick="location='/exam/question.php?type=english&amp;examid=202012&amp;mode=0&amp;no=3'"
            >
              Next
            </button>
          </div>
          <div class="right-topbar ml-auto">
            <div class="dropdown tools">
              <button
                class="btn md-button dropdown-toggle"
                type="button"
                id="toolbutton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="toolbutton"
              >
                <!-- <a class="dropdown-item disabled" href="#"> Magnifier</a> -->
                <span
                  id="highlight"
                  class="dropdown-item"
                  @click="setHighlighter()"
                >
                  <span v-show="canHighlighter">
                    <i class="fa fa-check"></i>
                  </span>
                  Highlighter
                </span>
                <span
                  id="eliminator"
                  class="dropdown-item "
                  @click="setEliminator()"
                >
                  <span v-show="canEliminator">
                    <i class="fa fa-check"></i>
                  </span>
                  Answer Eliminator
                </span>
                <span id="masking" class="dropdown-item" @click="setMasking()">
                  <span v-show="canMasking">
                    <i class="fa fa-check"></i>
                  </span>
                  Answer Masking
                </span>
              </div>
            </div>
            <div class="btn-group" role="group">
              <button
                type="button"
                id="md-zoom-in"
                class="btn md-button"
                @click="zoomIn()"
              >
                <i class="bi-zoom-in" style="font-size: .6rem;"></i>
              </button>
              <button
                type="button"
                id="md-zoom-refresh"
                class="btn md-button"
                @click="zoomRefresh()"
              >
                <i class="bi-arrow-clockwise" style="font-size: .6rem;"></i>
              </button>
              <button
                type="button"
                id="md-zoom-out"
                class="btn md-button"
                @click="zoomOut()"
              >
                <i class="bi-zoom-out" style="font-size: .6rem;"></i>
              </button>
            </div>
            <button
              type="button"
              class="btn md-button clr-button"
              id="clearHighlight"
            >
              <span class="normal">Clear Highlight</span>
            </button>
            <button
              type="button"
              class="btn md-button bi-x end-button"
              data-toggle="modal"
              data-target="#endsection"
            >
              End Section
            </button>
            <button type="button" class="btn md-button" id="timer">
              17:51
            </button>
            <div class="dropdown">
              <button
                type="button"
                class="btn md-button"
                id="userbutton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="bi-person" style="font-size: 1.2rem;"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="userbutton"
              >
                <a class="dropdown-item" href="/exam/profile.php">Profile</a>
                <a class="dropdown-item" href="/exam/history.php"
                  >Exam History</a
                >
                <div class="dropdown-divider mb-0"></div>
                <a class="dropdown-item" href="/user/logout.php">Logout</a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div class="page-wrapper" id="sandbox">
        <div class="sidebar-wrapper" data-simplebar="init">
          <div class="simplebar-wrapper" style="margin: -10px;">
            <div class="simplebar-height-auto-observer-wrapper">
              <div class="simplebar-height-auto-observer"></div>
            </div>
            <div class="simplebar-mask">
              <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                <div
                  class="simplebar-content-wrapper"
                  style="height: 100%; overflow: hidden;"
                >
                  <div class="simplebar-content" style="padding: 10px;">
                    <div class="sidebar-content">
                      <p class="articleTitle">Passage I</p>
                      <p class="articleName">Changing the Face of Journalism</p>
                      <p>
                        “The nation and its news media are going in opposite
                        directions, and the public is paying the price,” <u>i</u
                        ><u>n</u><u> 2013, Dori Maynard wrote this.</u> She
                        wasfollowing up on her point that although people of
                        color
                        <u style="background-color: rgb(255, 255, 0);">make</u>
                        up nearly 40 percent of the US population,
                        <u>while</u> only about 12percent of full-time daily
                        news journalists are people of color.
                        <u>Her who’s</u> anaward-winning African American
                        journalist, Maynard
                        <u
                          >became a Nieman fellow at Harvard University in
                          1993.</u
                        >
                      </p>
                      <p>
                        Formuch of her journalism career, she was involved with
                        the Maynard Institute, ajournalism-education institute
                        in Oakland, California. (The institute wasfounded in
                        1977 by her father, Robert C. Maynard, former editor of
                        the OaklandTribune.) <u>Conversely,</u> the organization
                        is dedicated torecruiting and training people of color
                        to become journalists. By the time DoriMaynard became
                        president of the institute in 2001, she was already
                        passionatelycommitted to this mission.
                      </p>
                      <p>
                        Diversityamong journalists in the United States must
                        increase, Maynard believed, <u>if</u> theindustry is
                        going to meet<u>its’</u> ethical obligation to provide
                        awide audience credible and accurate news. She argued
                        that a reporter’s culturalbackground inevitably
                        influences his or her perspective on current events.
                        Morediversity in newsrooms will naturally lead to more
                        complete, <u>nuanced,</u> andsensitive news coverage of
                        all communities, specifically communities of color
                        <u>expressly.</u> This,in turn, will better our society
                        as a whole; we’ll gain a richer understandingof each
                        other’s lives, she explained.
                      </p>
                      <p>
                        Under Dori Maynard’s leadership, theMaynard Institute
                        trained hundreds of minority reporters. The institute
                        alsohas helped them get hired and heard. Many former
                        trainees now <u>reports</u> for influential
                        <u>newspapers such as</u> the New York Times and
                        theWashington Post. <u>Dori Maynard</u> helped show the
                        journalismindustry that presenting the culturally
                        diverse voices of the United Statesthrough inclusive
                        news stories written by people of color
                        <u>is possible.</u>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="simplebar-placeholder"
              style="width: auto; height: 798px;"
            ></div>
          </div>
        </div>
        <div class="page-content-wrapper" style="height: auto !important;">
          <div class="page-content" style="height: auto !important;">
            <div class="myquestion">
              <p>
                <span class="myquestion-no">
                  <b>{{ questionOrder }}. </b>
                </span>
              </p>
              <div
                class="form-check"
                v-for="option in question.options"
                :key="option.order"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  :name="questionOrder"
                  :id="option.order"
                  :value="option.order"
                />
                <label class="form-check-label elim-option" :for="option.order">
                  {{ option.content }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button
          type="button"
          disabled="disabled"
          class="btn is-disabled flowright ft-button"
        >
          &lt;
        </button>
        <button type="button" class="btn ft-button is-disabled">Info</button>
        <button type="button" class="btn ft-button">Instr</button>
        <nav aria-label="Pagination">
          <ul class="pagination pagination-sm">
            <li
              class="page-item"
              v-for="question in questionsCount"
              :key="question"
            >
              <a
                :class="
                  question == questionOrder ? 'page-link active' : 'page-link'
                "
              >
                {{ question }}
              </a>
            </li>
            <li class="page-item"></li>
          </ul>
        </nav>
        <button type="button" class="btn flowleft ft-button">&gt;</button>
        <button type="button" class="btn ft-button flag-button bi-flag">
          Flag
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import $ from "jquery";
import TextHighlighter from "@/views/act/assets/js/TextHighlighter.js";

export default {
  metaInfo() {
    return {
      title: "ACT - ACT Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      questionsCount: 60,
      questionOrder: 2,
      currentZoom: 1,
      canHighlighter: false,
      canEliminator: false,
      canMasking: false,
      question: {
        title: "",
        options: [
          { order: 1, content: "NO CHANGE" },
          { order: 2, content: "does make" },
          { order: 3, content: "is making" },
          { order: 4, content: "makes" }
        ]
      }
    };
  },
  computed: {},
  watch: {
    currentZoom() {
      $(".wrapper").animate({ zoom: this.currentZoom }, "slow");
    }
  },

  mounted() {},

  methods: {
    setHighlighter() {
      this.canHighlighter = !this.canHighlighter;
      console.log(this.canHighlighter);
      if(this.canHighlighter){

        var sandbox = $("#sandbox")[0];
        this.hltr = new TextHighlighter(sandbox);
      }else {
        this.hltr.doHighlight = () => {};
      }
    },
    setEliminator() {
      this.canEliminator = !this.canEliminator;
    },
    setMasking() {
      this.canMasking = !this.canMasking;
    },
    zoomIn() {
      this.currentZoom += 0.1;
      console.log(this.currentZoom);
      $(".wrapper").animate({ zoom: this.currentZoom }, "slow");
    },
    zoomOut() {
      this.currentZoom -= 0.1;
    },
    zoomRefresh() {
      this.currentZoom = 1;
    }
  }
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>

<style scoped src="@/views/act/assets/css/simplebar.css"></style>
